export function updateUserEntitiesWithUnreadCounts(currentUser, newEntities) {
  const updatedEntities = currentUser.entities.map((entity) => {
    const newEntity = newEntities.find((ne) => ne.id === entity.id);
    if (newEntity) {
      return {
        ...entity,
        hasUnreadCommunication: newEntity.hasUnreadCommunication,
        hasUnreadMoment: newEntity.hasUnreadMoment,
      };
    }
    return entity;
  });
  return { ...currentUser, entities: updatedEntities };
}
