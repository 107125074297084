import { updateUserEntitiesWithUnreadCounts } from '../updateUserEntitiesWithUnreadCounts';

describe('updateUserEntitiesWithUnreadCounts', () => {
  it('should update entities with unread counts from newEntities', () => {
    const currentUser = {
      entities: [
        { id: 1, hasUnreadCommunication: false, hasUnreadMoment: false },
        { id: 2, hasUnreadCommunication: false, hasUnreadMoment: false },
      ],
    };

    const newEntities = [
      { id: 1, hasUnreadCommunication: true, hasUnreadMoment: true },
      { id: 2, hasUnreadCommunication: false, hasUnreadMoment: true },
    ];

    const expected = {
      entities: [
        { id: 1, hasUnreadCommunication: true, hasUnreadMoment: true },
        { id: 2, hasUnreadCommunication: false, hasUnreadMoment: true },
      ],
    };

    const result = updateUserEntitiesWithUnreadCounts(currentUser, newEntities);
    expect(result).toEqual(expected);
  });

  it('should not update entities if no matching id is found in newEntities', () => {
    const currentUser = {
      entities: [
        { id: 1, hasUnreadCommunication: false, hasUnreadMoment: false },
        { id: 2, hasUnreadCommunication: false, hasUnreadMoment: false },
      ],
    };

    const newEntities = [
      { id: 3, hasUnreadCommunication: true, hasUnreadMoment: true },
    ];

    const expected = {
      entities: [
        { id: 1, hasUnreadCommunication: false, hasUnreadMoment: false },
        { id: 2, hasUnreadCommunication: false, hasUnreadMoment: false },
      ],
    };

    const result = updateUserEntitiesWithUnreadCounts(currentUser, newEntities);
    expect(result).toEqual(expected);
  });

  it('should return a new object and not mutate the original currentUser', () => {
    const currentUser = {
      entities: [
        { id: 1, hasUnreadCommunication: false, hasUnreadMoment: false },
        { id: 2, hasUnreadCommunication: false, hasUnreadMoment: false },
      ],
    };

    const newEntities = [
      { id: 1, hasUnreadCommunication: true, hasUnreadMoment: true },
    ];

    const result = updateUserEntitiesWithUnreadCounts(currentUser, newEntities);
    expect(result).not.toBe(currentUser);
    expect(result.entities).not.toBe(currentUser.entities);
  });
});
