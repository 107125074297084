import { changeConversationUnreadNotificationCounter } from '../changeConversationUnreadNotificationCounter';

describe('changeConversationUnreadNotificationCounter', () => {
  let instance;

  beforeEach(() => {
    instance = {
      state: {
        unreadConversationCount: 0,
      },
      setState: function (newState) {
        this.state = { ...this.state, ...newState(this.state) };
      },
    };
  });

  it('should increment unreadConversationCount by 1', () => {
    changeConversationUnreadNotificationCounter.call(instance, 'increment');
    expect(instance.state.unreadConversationCount).toEqual(1);
  });

  it('should decrement unreadConversationCount by 1', () => {
    instance.state.unreadConversationCount = 2;
    changeConversationUnreadNotificationCounter.call(instance, 'decrement');
    expect(instance.state.unreadConversationCount).toEqual(1);
  });

  it('should not decrement unreadConversationCount below 0', () => {
    changeConversationUnreadNotificationCounter.call(instance, 'decrement');
    expect(instance.state.unreadConversationCount).toEqual(0);
  });
});
