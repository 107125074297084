import { getChannelUnreadNotificationRepository } from '../../../app/Data/Repository/Entity/GetChannelUnreadNotification';

export function fetchEntityUnreadNotifications() {
  if (this.props?.store?.entity?.id) {
    getChannelUnreadNotificationRepository({
      entityId: this.props.store.entity.id,
    }).then((response) => {
      if (response.data) {
        this.setState({
          unreadCommunicationCount: response.data.node.messages.totalCount,
          unreadMomentCount: response.data.node.moments.totalCount,
          unreadConversationCount: response.data.node.directUnreadTotalCount,
        });
      }
    });
  }
}
