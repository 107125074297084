import { fetchEntityUnreadNotifications } from '../fetchEntityUnreadNotifications';
import * as repository from '../../../../app/Data/Repository/Entity/GetChannelUnreadNotification';

describe('given the "fetchEntityUnreadNotifications" function', () => {
  function setup({ noData = false }) {
    const data = {
      node: {
        messages: {
          totalCount: 1,
        },
        moments: {
          totalCount: 2,
        },
        directUnreadTotalCount: 3,
      },
    };

    jest
      .spyOn(repository, 'getChannelUnreadNotificationRepository')
      .mockResolvedValue(noData ? { data: null } : { data });
  }

  beforeEach(() => {
    jest.restoreAllMocks();
  });

  it('should call getChannelUnreadNotificationRepository with the correct params', () => {
    setup({});
    const context = {
      props: {
        store: {
          entity: {
            id: 10,
          },
        },
      },
      setState: jest.fn(),
    };

    fetchEntityUnreadNotifications.call(context);
    expect(
      repository.getChannelUnreadNotificationRepository,
    ).toHaveBeenCalledWith({
      entityId: 10,
    });
  });

  it('should set the state with the correct values', async () => {
    setup({});
    const context = {
      props: {
        store: {
          entity: {
            id: 10,
          },
        },
      },
      setState: jest.fn(),
    };

    await fetchEntityUnreadNotifications.call(context);
    expect(context.setState).toHaveBeenCalledWith({
      unreadCommunicationCount: 1,
      unreadMomentCount: 2,
      unreadConversationCount: 3,
    });
  });

  it('should not set the state if there is no data', async () => {
    setup({ noData: true });
    const context = {
      props: {
        store: {
          entity: {
            id: 10,
          },
        },
      },
      setState: jest.fn(),
    };

    await fetchEntityUnreadNotifications.call(context);
    expect(context.setState).not.toHaveBeenCalled();
  });

  it('should not call getChannelUnreadNotificationRepository if there is no entity', async () => {
    setup({});
    const context = {
      props: {
        store: {},
      },
      setState: jest.fn(),
    };

    await fetchEntityUnreadNotifications.call(context);
    expect(
      repository.getChannelUnreadNotificationRepository,
    ).not.toHaveBeenCalled();
  });
});
