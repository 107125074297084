/**
 *
 * @param {'increment' | 'decrement' } param
 */
export function changeConversationUnreadNotificationCounter(param) {
  if (param === 'increment') {
    this.setState((prevState) => ({
      unreadConversationCount: prevState.unreadConversationCount + 1,
    }));
  }

  if (param === 'decrement') {
    const newValue = this.state.unreadConversationCount - 1;

    this.setState(() => ({
      unreadConversationCount: newValue < 0 ? 0 : newValue,
    }));
  }
}
