import React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import gql from 'graphql-tag';
import { Dropdown, Icon } from 'semantic-ui-react';

import Form from '../../components/ui/Form';
import Avatar from '../../components/Avatar';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import EntityItem from '../Entity/Item';
import AlertEntitiesWithMessageScheduled from '../../components/AlertEntitiesWithMessageScheduled';
import { __ } from '../../i18n';

@inject('store', 'client') @observer
export default class EntityPatch extends Form {
  constructor(props) {
    super(props);
    this.state.entities = props.entities;
  }

  defaultValues = {
    entities: [],
    name: 'disable'
  }

  rules = {
    entities: [(val) => {
      if (val.length > 0) return true;
      const { type } = this.props;
      const error = type === 'entity' ? __('Add an existing entity and try again.') : __('Add an existing channel and try again.');
      throw new Error(error);
    }],
  }

  // Fetch for entities on search
  onSearchChange = (e, value) => this.props.client.query({
    query: gql`
      query EntityPatchQuery($id: ID!, $search: String, $isChannel: Boolean, $type: [EntityType]) {
      node (id: $id) {
        ... on Organization {
          id: dbId
          entities(search: $search, type: $type, isChannel: $isChannel) {
            nodes {
              id: dbId
              fullname
              picture {
                uri
                id: dbId
                key
              }
              ... EntityItemEntity
            }
          }
        }
      }
    }
    ${EntityItem.fragments.entity}`,
    variables: {
      id: this.props.store.currentOrganization.id,
      search: value.searchQuery,
      isChannel: this.props.type !== 'entity',
      type: this.props.entityTypes || undefined
    }
  }).then((data) => {
    const entities = this.state.entities.concat(data.data.node.entities.nodes)
      .filter((entity, i, self) => self.findIndex(s => entity.id === s.id) === i);
    this.setState({ entities });
  });

  renderLabel = label => ({
    image: <Avatar avatar spaced="right" src={label.entity.picture && label.entity.picture.uri} alt={label.entity.fullname} />,
    content: label.text
  })

  getEntities = () =>
    this.state.entities.map(entity => (
      {
        text: entity.fullname,
        value: entity.id,
        content: <EntityItem entity={entity} />,
        entity
      }
    ))

  beforeOnSubmit = () => this.setState({ loading: true });

  afterOnSubmit = () => this.setState({ loading: false });

  render() {
    const { type, pageName } = this.props;
    const { values, loading, errors } = this.state;

    return (
      <Form id="EntityPatch" {...this.props} loading={loading} onSubmit={this.handleSubmit} beforeOnSubmit={this.beforeOnSubmit} afterOnSubmit={this.afterOnSubmit}>
        <p style={{ fontSize: '1.125rem', fontWeight: 'bold', margin: '0px' }}>{this.props.message}</p>
        {
          values.name === 'delete' &&
          <div style={{ width: '100%', height: '60px', backgroundColor: '#FFEBE6', borderRadius: '8px', display: 'flex', flexDirection: 'row', marginBottom: '24px', alignItems: 'center' }}>
            <div style={{ width: '11%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Icon name={'exclamation circle'} style={{ color: '#BF2600', margin: null, fontWeight: 'bold' }} size="large" />
            </div>
            <div style={{ width: '89%', height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              {
                type === 'entity' ?
                  <span>
                    {__('Attention, when deleting these people, ALL messages')} <b>{__('will be deleted and there is no way to recover them.')}</b>
                  </span>
                  :
                  <span>
                    {__('Attention, when deleting these channels, ALL messages')} <b>{__('will be deleted and there is no way to recover them.')}</b>
                  </span>
              }
            </div>
          </div>
        }

        <Form.MultipleDropdown
          reference={(c) => { this.entitiesDropdown = c; }}
          name="entities"
          value={toJS(values.entities)}
          options={this.getEntities()}
          error={errors && errors.entities}
          renderLabel={this.renderLabel}
          onChange={(e, data) => this.onSelectionChange(e, data, () => this.entitiesDropdown.clearSearchQuery())}
          onSearchChange={this.onSearchChange}
          noResultsMessage={__('No results were found')}
        />
        
        {!loading && values.name == 'disable' &&
          <div style={{ marginTop: '1rem' }}>
            <AlertEntitiesWithMessageScheduled 
              entities={toJS(this.state.entities).filter(e => toJS(values.entities).includes(e.id)).map(e => ({ id: e.id, fullname: e.fullname }))}
              store={toJS(this.props.store)}
              pageName={pageName}
            />
          </div>
        }

        {values.invite &&
        <div style={{ marginTop: '1rem' }}>
          <p style={{ fontSize: '1.125rem', fontWeight: 'bold' }}>{__('Invitation type')}</p>
          <div style={{ marginTop: '1.8rem' }}>
            <ColoredCheckbox
              radio
              bold={false}
              radioChecked
              checked={values.invite === 'EMAIL'}
              color="#084FFF"
              label={__('Email')}
              onClick={() => this.onSelectionChange(null, { name: 'invite', value: 'EMAIL' })}
              style={{ width: '24px', height: '24px', fontWeight: 'normal', marginRight: '10px' }}
            />
          </div>
          <div style={{ marginTop: '1rem' }}>
            <ColoredCheckbox
              radio
              bold={false}
              radioChecked
              checked={values.invite === 'PHONE'}
              color="#084FFF"
              label={__('Phone')}
              onClick={() => this.onSelectionChange(null, { name: 'invite', value: 'PHONE' })}
              style={{ width: '24px', height: '24px', fontWeight: 'normal', marginRight: '10px' }}
            />
          </div>
        </div>
        }
      </Form>
    );
  }
}
